// export const apiURL = "http://localhost:1337";
export const apiLocalURL = "http://localhost:1337/";
// export const innerUploadUrl = "http://localhost:1337";
// export const uploadUrl = "http://localhost:1337";
// export const siteUrl = "http://localhost:3000/";
export const backendurl = "http://localhost:1337/";

export const apiURL = "https://organograma.bamboodigital.me/cms";
export const uploadUrl = "https://organograma.bamboodigital.me/cms";
export const innerUploadUrl = "https://organograma.bamboodigital.me/cms";
export const siteUrl = "https://organograma.bamboodigital.me/";
// export const backendurl = "https://organograma.bamboodigital.me/cms/";
